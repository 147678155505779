<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}

      <span class="d-none d-sm-inline-block">, Tüm Hakları Saklıdır</span>
    </span>

    <span class="float-md-right d-none d-md-block"
      >Sanal TUS Koçunuz
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
export default {};
</script>
