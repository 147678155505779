export default [
  {
    title: "TUS Denemeleri",
    route: "deneme-sinavlari",
    icon: "BookOpenIcon",
  },
  {
    title: "Ders Denemeleri",
    route: "ders-denemeleri",
    icon: "BookOpenIcon",
  },
  {
    title: "Soru İstatistikleri",
    route: "soru-istatistikleri",
    icon: "BarChart2Icon",
  },
  {
    title: "ÖSYM Soruları",
    route: "osym-sorulari",
    icon: "BookOpenIcon",
  },

  {
    title: "Kadro Anketleri",
    route: "kadro-anketleri",
    icon: "MessageSquareIcon",
  },
  {
    title: "Ç. Seçmeli Anketler",
    route: "coktan-secmeli-anketler",
    icon: "MessageSquareIcon",
  },
  {
    title: "Açık Uçlu Anketler",
    route: "acik-uclu-anketler",
    icon: "MessageSquareIcon",
  },
  {
    title: "Üyeler",
    route: "kullanicilar",
    icon: "UsersIcon",
  },
  {
    title: "SMS Gönderimi",
    route: "sms-servisi",
    icon: "MessageSquareIcon",
  },

  {
    title: "TUSBuddy İstatistikler",
    route: "app_stats",
    icon: "ActivityIcon",
  },
];
