<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <mobile-app-links></mobile-app-links>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="nav-link" />

      <b-nav-item-dropdown
        v-if="isLoggedIn()"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              <span class="text-primary font-weight-bolder">Admin Paneli</span>
              <br />
              {{ adminObject.user_name }}
            </p>
          </div>
          <b-avatar
            size="32"
            variant="light-primary"
            style="padding: 2px"
            :src="require('@/assets/images/auth/profile.svg')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Çıkış Yap</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <template v-else>
        <div
          class="d-sm-flex d-none user-nav"
          style="cursor: pointer"
          @click="logout()"
        >
          <p class="user-name font-weight-bolder mb-0 text-primary">
            Giriş Yap
          </p>
        </div>
        <img
          @click="logout()"
          :src="require('@/assets/images/auth/login.svg')"
          width="26"
          height="26"
          style="cursor: pointer; margin-left: 10px"
          alt=""
        />
      </template>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import MobileAppLinks from "@core/layouts/components/MobileAppLinks.vue";
import { initialAbility } from "@/libs/acl/config";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    MobileAppLinks,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    // Navbar Components
    DarkToggler,
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      windowHeight: 0,
    };
  },
  computed: {
    adminObject() {
      return JSON.parse(localStorage.getItem("adminObject"));
    },
  },
  methods: {
    isInFullScreen() {
      var screenHeight = screen.height;
      if (
        this.windowHeight &&
        this.windowHeight > 0 &&
        screenHeight &&
        screenHeight > 0 &&
        Math.abs(screenHeight - this.windowHeight) < 10
      ) {
        return true;
      }
      return false;
    },

    isLoggedIn() {
      return this.adminObject != null;
    },

    logout() {
      // Remove userData from localStorage
      localStorage.removeItem("adminObject");
      localStorage.removeItem("accessToken");

      this.$router.push("/giris").catch(() => {});
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },

};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.navbar-floating .navbar-container {
  padding: 0.6rem 0.5rem;
}
@media only screen and (min-width: 540px) {
  .navbar-floating .navbar-container {
    padding: 0.8rem 1rem;
  }
}
</style>

