<template>
  <layout-vertical>
    <router-view v-if="adminObject" />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import { $themeConfig } from "@themeConfig";
import Navbar from "../components/Navbar.vue";
import router from "@/router";
import store from "@/store/index";

export default {
  components: {
    LayoutVertical,
    Navbar,
  },
  computed: {
    adminObject() {
      return JSON.parse(localStorage.getItem("adminObject"));
    },
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },

  async beforeMount() {
    var url = window && window.location ? window.location.href : "";
    if (
      url != null &&
      (url.includes("premium") ||
        url.includes("anket-ekle") ||
        url.includes("stats") ||
        url.includes("sms"))
    ) {
      return;
    }
    if (!this.adminObject) {
      this.$router.push("/giris").catch(() => {});
    } else {
      await store.dispatch("auth/getUserByPhoneNumber", this.adminObject.phone);
    }
  },
};
</script>
